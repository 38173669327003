<template>
  <div
    class="order-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
<!--    <template v-if="detail.orderStatus == 1 && countdown != 0">
      <div class="countdown">
        {{ PAGELANG.支付倒计时1 }} <span>{{ countdown }}</span>
        {{ PAGELANG.支付倒计时2 }}
      </div>
      <div class="countdown-placeholder"></div>
    </template> -->
	
	<van-overlay :show="showOverlay" @click="closeshowOverlay()">
	  <div class="goods-money-pay-content" @click.stop >
		  <div style="font-size:14px;color:#999999">確認訂單金額</div>
		  <div style="font-size:22px;margin:10px 0px;color:red">NT${{ detail.totalMoney/100}}.00</div>
		  <div style="margin:10px 0px;">
			<div style="margin-bottom:20px;font-size:14px;color:#999999">請選擇訂單支付方式</div>
			<van-radio-group v-model="payType">
			  <van-radio name="2" style="margin-bottom:20px;">庫存貨物<span style="color: red;">剩餘({{moneyInfo.goodsMoney/100}})</span></van-radio>
			  <van-radio name="18">歷史積分<span style="color: red;">剩餘({{moneyInfo.transferMoney/100}})</span></van-radio>
			</van-radio-group>
		  </div>
		  <div>请输入支付密码</div> 
		  <div>
				<input
				  class="custom-input"
				  v-model="password"
				  type="password"
				/>   			  
		  </div>
		 <div style="display: flex;justify-content: space-around;margin-top:20px;align-items: center;">
		   <van-button type="primary" size="normal" text="确认支付" @click="isshowOverlay()" />
		   <!-- <van-button type="warning" size="small" text="忘记密码" @click="isshowOverlay()" />	 -->
		   <a href="#/resetPassword" style="color: #999;text-decoration: underline;font-size: 14px;">
				<span>
				  忘记密码
				</span>
		   </a>
		 </div>
	  </div>
	</van-overlay>	
	
	
   <!-- <div class="order-content status">
      <span class="status-title">{{
        detail.orderStatusDesc ? detail.orderStatusDesc : ""
      }}</span>
      <span class="status-desc">{{
        detail.CurStatusObj ? detail.CurStatusObj.desc : ""
      }}</span>
    </div>
    <div v-if="detail.LogisticInfo" class="order-content">
      {{ PAGELANG.wuliuxinxi }}
    </div> -->

    <div class="order-content address">
      <div class="info">
        <span class="area">{{ detail.city }}{{ detail.district }}{{ detail.detail }}</span>
        <span class="phone"
          >{{ detail.buyerNickName }} {{ detail.buyerPhone }}</span
        >
      </div>
    </div>
	

    <div class="order-content">
      <div class="big-title">{{ PAGELANG.gouwuqingdan }}</div>
      <div class="order-goods-list">
        <template v-for="(item, index) in goods" :key="index">
          <div class="goods">
            <van-image
              width="100"
              height="100"
              radius="8"
              :src="item.productImage"
              fit="cover"
            />
            <div class="info">
              <div class="pdt-name">
                <span class="name">{{ item.skuName }}</span>
              </div>
              <div class="pdt-props">
                <span>{{ item.properties }}</span>
              </div>
              <div class="pdt-props">
                <span class="price">{{ item.skuTaxFreight/100 }}.00</span>
                <span class="buy-count">×{{ item.quantity }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="order-content">
      <ul class="order-info">
        <li>
          <label class="title">总价</label>
          <span class="value price">{{ detail.totalMoney/100}}.00</span>
        </li>
        <li>
          <label class="title">合计</label>
          <span class="value price total-money">{{ detail.totalMoney/100}}.00</span>
        </li>
      </ul>
      <ul class="order-info">
        <li>
          <label class="title">{{ PAGELANG.ordernum }}</label>
          <span class="value">
            {{ detail.orderCode }}
            |
            <label class="copy" @click="onCopy(detail.orderCode)">{{
              SYSLANG.copy
            }}</label></span
          >
        </li>
		<li> <label class="title">快遞公司:{{ detail.expressName }}</label></li>
		<li>
		  <label class="title">快递单号</label>
		  <span class="value">
		    {{ detail.expressCode }}
		    |
		    <label class="copy" @click="onCopy(detail.orderCode)">{{
		      SYSLANG.copy
		    }}</label></span
		  >
		</li>
        <li>
          <label class="title">{{ PAGELANG.adddtime }}</label>
          <span class="value">{{ detail.createDate }}</span>
        </li>
        <li v-if="detail.PayDTime > 0">
          <label class="title">{{ PAGELANG.paydtime }}</label>
          <span class="value">{{ detail.PayDTimeFormat }}</span>
        </li>
        <li v-if="detail.DeliverDTime > 0">
          <label class="title">{{ PAGELANG.deliverdtime }}</label>
          <span class="value">{{ detail.DeliverDTimeFormat }}</span>
        </li>
      </ul>
    </div>

<!--    <div v-if="detail.CurStatus == 1" class="order-content">
      <div class="big-title">
        {{ PAGELANG.credit }}
        <span class="credit price">{{ detail.Credit }}</span>
      </div>
    </div>
 -->
<!--    <div class="control-block">
      <van-button plain type="primary" @click="goBack">{{
        SYSLANG.fanhui
      }}</van-button>
      <van-button
        v-if="detail.CurStatus > 2 && detail.ShippingNo"
        plain
        type="primary"
        >{{ PAGELANG.viewwuliu }}</van-button
      >
      <van-button
        v-if="detail.CurStatus == 3"
        type="primary"
        @click="confirmReceipt"
        >{{ PAGELANG.确认收货 }}</van-button
      >
      <van-button
        v-if="detail.orderStatus == 1"
        plain
        type="danger"
        @click="cancelOrder()"
        color="#fe295f"
        >{{ PAGELANG.取消订单 }}</van-button
      >
      <van-button
        v-if="detail.orderStatus == 10 || detail.orderStatus == 100"
        plain
        type="danger"
        color="#fe295f"
        @click="deleteOrder()"
        >{{ PAGELANG.删除订单 }}</van-button
      >
      <van-button v-if="detail.orderStatus == 1" type="primary" @click="toPay">{{
        PAGELANG.topay
      }}</van-button>
    </div> -->
  </div>

<!--  <div
    class="pc-order-pre"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="order-content status">
      <span class="status-title">
        {{ detail.CurStatusObj ? detail.CurStatusObj.title : "" }}
        <span v-if="detail.CurStatus == 1 && countdown != 0" class="countdown">
          {{ PAGELANG.支付倒计时3 }}{{ PAGELANG.支付倒计时1 }}
          <span>{{ countdown }}</span>
          {{ PAGELANG.支付倒计时2 }}
        </span>
      </span>
      <span class="status-desc">{{
        detail.CurStatusObj ? detail.CurStatusObj.desc : ""
      }}</span>
    </div>
    <div v-if="detail.LogisticInfo" class="order-content">
      {{ PAGELANG.wuliuxinxi }}
    </div>

    <div class="order-content">
      <div class="big-title">{{ PAGELANG.收货地址 }}</div>
      <div class="address-block" style="margin-bottom: 0">
        <ul class="address">
          <li>
            {{ detail.ShippingAddr }}
            <span class="phone">
              {{ detail.ReceiverName }} {{ detail.MobilePhone }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="order-content">
      <div class="big-title">
        {{ PAGELANG.gouwuqingdan }}
      </div>
      <div class="pc-cart">
        <ul class="my-cart">
          <li class="cart-item header">
            <div class="pdt-name">{{ CARTLANG.商品信息 }}</div>
            <div class="price">{{ CARTLANG.价格 }}</div>
            <div class="count">{{ CARTLANG.buycount }}</div>
            <div class="control">{{ CARTLANG.小计 }}</div>
          </li>
          <li
            v-for="(item, index) in detail.ShippingList"
            :key="index"
            class="cart-item"
          >
            <van-image
              width="100"
              height="100"
              radius="0"
              :src="UPLOADBASE + item.GoodsRow.PdtThumb"
              fit="cover"
              class="thumb"
            />
            <div class="pdt-name">
              <div class="title" @click="viewGoods(item.GoodsRow.ID)">
                {{ item.GoodsRow.PdtName }}
              </div>
              <div class="props">{{ item.SelPropsText }}</div>
            </div>
            <div class="price">
              {{ item.RPriceProp }}
            </div>
            <div class="count">{{ item.BuyCount }}</div>
            <div class="control price">
              {{ item.TotalRPrice }}
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="order-content order-info">
      <div class="c">
        <div class="big-title">
          {{ PAGELANG.remark }}
        </div>
        <el-input
          v-model="detail.OrderBak"
          :rows="4"
          type="textarea"
          resize="none"
          class="remark"
          :disabled="true"
          style="width: 80%"
        />
      </div>
      <div class="c">
        <div class="big-title">
          {{ PAGELANG.订单信息 }}
        </div>
        <ul class="info">
          <li>
            <label class="title">{{ PAGELANG.ordermoney }}</label>
            <span class="value price">{{ detail.OrderMoney }}</span>
          </li>
          <li>
            <label class="title">{{ PAGELANG.freight }}</label>
            <span class="value price">{{ detail.ShippingFee }}</span>
          </li>
          <li>
            <label class="title">{{ PAGELANG.totalmoney }}</label>
            <span class="value price total-money">{{ detail.TotalMoney }}</span>
          </li>
        </ul>
        <ul class="info">
          <li>
            <label class="title">{{ PAGELANG.ordernum }}</label>
            <span class="value">
              {{ detail.OrderUID }}
              |
              <label class="copy" @click="onCopy(detail.OrderUID)">{{
                SYSLANG.copy
              }}</label></span
            >
          </li>
          <li>
            <label class="title">{{ PAGELANG.adddtime }}</label>
            <span class="value">{{detail.createDate}}</span>
          </li>
          <li v-if="detail.PayDTime > 0">
            <label class="title">{{ PAGELANG.paydtime }}</label>
            <span class="value">{{ detail.PayDTimeFormat }}</span>
          </li>
          <li v-if="detail.DeliverDTime > 0">
            <label class="title">{{ PAGELANG.deliverdtime }}</label>
            <span class="value">{{ detail.DeliverDTimeFormat }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="order-content">
      <div class="pc-cart">
        <div class="cart-menu">
          <van-button plain type="primary" @click="goBack">{{
            SYSLANG.fanhui
          }}</van-button>
          <div class="submit">
            <span v-if="detail.CurStatus == 1" class="freight">
              {{ PAGELANG.credit + ": " }}
              <label class="price">{{ detail.Credit }}</label>
            </span>
            <van-button
              v-if="detail.orderStatus > 2 && detail.ShippingNo"
              plain
              type="primary"
              >{{ PAGELANG.viewwuliu }}</van-button
            >
            <van-button
              v-if="detail.orderStatus == 4"
              type="primary"
              @click="confirmReceipt"
              >{{ PAGELANG.确认收货 }}</van-button
            >
            <van-button
              v-if="detail.orderStatus == 1"
              plain
              type="primary"
              @click="cancelOrder"
              style="margin-right: calc(100vw / 1920 * 10)"
              >{{ PAGELANG.取消订单 }}</van-button
            >
            <van-button
              v-if="detail.orderStatus == 10 || detail.orderStatus == 100"
              plain
              type="primary"
              @click="deleteOrder"
              style="margin-right: calc(100vw / 1920 * 10)"
              >{{ PAGELANG.删除订单 }}</van-button
            >
            <van-button
              v-if="detail.orderStatus == 1"
              type="primary"
              @click="toPay"
              >{{ PAGELANG.topay }}</van-button
            >
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import {
  closeToast,
  showConfirmDialog,
  showLoadingToast,
  showToast,
} from "vant";
import md5 from 'md5'
import { getCookie,formatDateTime} from "../util";
export default {
  data() {
    return {
      orderid: 0,
	  payType:2,
      detail: {},
	  showOverlay:false,
      timer: null,
      isfrom: "",
      countdown: "30:00",
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	
	this.axios.get(this.actions.memberMoney).then((response) => {
	  closeToast();
	 let { code, message, data } = response.data;
	  if (code == "200") {
	    this.moneyInfo = data;
	  } else {
	    showToast({
	      message:message,
	      forbidClick: true,
	      onClose: () => {
	        if (code == 400) {
	          this.$router.replace({
	            path: "/login",
	          });
	        }
	      },
	    });
	  }
	}); 

    this.PAGELANG = this.LANG.pages.order;
    this.ADDRLANG = this.LANG.pages.address;
    this.CARTLANG = this.LANG.pages.cart;
    this.SYSLANG = this.LANG.system;
    this.UPLOADBASE = this.uploadbase;

    if (this.$route.query.orderid) {
      this.orderid = this.$route.query.orderid;
	  this.orderStatus = this.$route.query.orderStatus;
    }

    if (this.$route.query.isfrom) {
      this.isfrom = this.$route.query.isfrom;
    }

    if (getCookie("agentlogintoken")) {
      this.init();
    }
  },
  methods: {
    init() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
      this.axios
        .get(this.actions.orderTariffList,{params:{orderCode:this.orderid,status:0,orderStatus:this.orderStatus}})
        .then((res) => {
          closeToast();
          let { code, msg} = res.data;
          if (code == 200) {
			 this.detail=res.data.data.list[0],
			 this.goods=res.data.data.list[0].skuBeanList;		
			 this.detail.createDate=formatDateTime(this.detail.createDate)
              if (this.detail.orderStatus * 1 == 1) {
                // 待付款
                //this.startCountdown();
              }
          }else{
            showToast({
              message:
                code == "NOTLOGIN" ? this.SYSLANG[msg] : this.PAGELANG[msg],
              forbidClick: true,
              onClose: () => {
                if (code == "NOTLOGIN") {
                  // this.$router.replace({
                  //   path: "/login",
                  // });
                } else {
                  //this.$router.back();
                }
              },
            });
          }
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.OrderDetail.init.error", error);
          closeToast();
          //this.init();
        });
    },
    onCopy(content) {
      let oInput = document.createElement("input");
      oInput.value = content;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      showToast(this.SYSLANG.copysuccess);
    },
    toPay() {
	  this.showOverlay=true
	  this.orderCode=this.detail.orderCode	
    },
	isshowOverlay(){
		this.axios
		  .post(this.actions.getPayments,{orderCode:this.orderCode,payType:this.payType}).then((res)=>{
			   if(res.data.code==200){
				   console.log(res.data.data.payConfig.tradeNo)
				   this.tradeNo=res.data.data.payConfig.tradeNo
				   
				   this.axios
				     .post(this.actions.validatePassword,{password:md5(this.password)}).then((res)=>{
				   	   if(res.data.code==200){			    
				   			this.axios
				   			  .post(this.actions.getPay,{token:res.data.data.token,tradeNo:this.tradeNo}).then((res)=>{
				   				     console.log('res.data.code',res.data.code)
				   				   if(res.data.code==200){
				   					  showToast({
				   						  message:res.data.message,
				   						  forbidClick: true,
				   						  onClose: () => {
				   						            for (let key in history.state) {
				   						              delete history.state[key];
				   						            }
				   						            this.$router.replace({
				   						              path: "/order/detail",
				   						              query: {
				   						                orderid:this.orderCode,
				   						                isfrom: "placeorder",
				   						              },
				   						            });
				   									this.init();
				   						            this.showOverlay=false
				   						        },
				   						  
				   					  }) 
				   				   }else{
				   					   showToast({
				   					     message:res.data.message,
				   					     forbidClick: true,
				   					     onClose: () => {
				   					     },
				   					   });
				   				   }
				   			  })
				   		    
				   	   }else{
				   		   showToast({
				   		     message:res.data.message,
				   		     forbidClick: true,
				   		     onClose: () => {
				   		     },
				   		   });
				   	   }
				   	  
				     })
			   }else{
				   showToast({
				     message:res.data.message,
				     forbidClick: true,
				     onClose: () => {
				     },
				   });
			   }
			  
	    })
	},
	closeshowOverlay(){
		 this.showOverlay=false
		// this.$router.replace({
		//   path: "/order/detail",
		//   query: {
		//     orderid:this.orderCode,
		//     isfrom: "placeorder",
		//   },
		// });
	},
	
	
    goBack() {
      if (this.isfrom == "placeorder") {
        this.$router.replace({
          path: "/order",
        });
      } else {
        this.$router.back();
      }
    },
    // 确认收货
    confirmReceipt() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.请谨慎收货,
      })
        .then(() => {
          this.axios
            .get(this.actions.order_receipt + this.detail.ID)
            .then(({ data }) => {
              let { code, msg } = data;
              console.log(code, msg);
              showToast({
                message: msg,
                forbidClick: true,
                onClose: () => {
                  this.init();
                },
              });
            });
        })
        .catch((error) => {
          //请求失败，触发catch中的函数 可省略
          console.log("views.OrderDetail.confirmReceipt.error", error);
          closeToast();
        });
    },
    // 开始倒计时
    startCountdown() {
      var timestamp = Math.round(new Date().getTime() / 1000);
      var ts = this.detail.PayTimeout - timestamp;

      var mm = parseInt((ts / 60) % 60, 10);
      var ss = parseInt(ts % 60, 10);

      console.log(mm, ss);

      this.countdown =
        (mm > 0 ? (mm < 10 ? "0" : "") + mm + ":" : "") +
        (ss < 10 ? "0" : "") +
        ss;

      if (mm > 0 || ss > 0) {
        setTimeout(this.startCountdown, 1000);
      } else {
        // 支付超时
        this.axios
          .get(this.actions.order_timeout + this.detail.ID)
          .then(({ data }) => {
            closeToast();
            let { code } = data;
            if (code == "SUCCESS") {
              this.init();
            }
          })
          .catch((error) => {
            //请求失败，触发catch中的函数 可省略
            console.log(
              "views.OrderDetail.startCountdown.order_timeout.error",
              error
            );
            closeToast();
          });
      }
    },
    // 取消订单
    cancelOrder() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要取消当前订单,
        confirmButtonText: this.SYSLANG.queding,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.PAGELANG.正在取消订单,
            duration: 0,
            forbidClick: true,
          });
		  this.axios
		    .post(this.actions.orderCancel,{orderCode:this.detail.orderCode})	
            .then(({ data }) => {
              closeToast();
              let { code, msg } = data;
              if (code == 200) {
                this.init();
              } else {
                showToast({
                  message:
                    code == "NOTLOGIN"
                      ? this.SYSLANG[msg]
                      : code == "ERROR" || code == "SUCCESS"
                      ? this.PAGELANG[msg]
                      : msg,
                  forbidClick: true,
                });
              }
            })
            .catch((error) => {
              //请求失败，触发catch中的函数 可省略
              console.log(
                "views.OrderDetail.cancelOrder.order_cancel.error",
                error
              );
              closeToast();
            });
        })
        .catch(() => {});
    },
    // 删除订单
    deleteOrder() {
      showConfirmDialog({
        title: this.SYSLANG.dialogtitle,
        message: this.PAGELANG.您确定要删除当前订单,
        confirmButtonText: this.SYSLANG.delete,
        cancelButtonText: this.SYSLANG.quxiao,
      })
        .then(() => {
          showLoadingToast({
            message: this.PAGELANG.正在删除订单,
            duration: 0,
            forbidClick: true,
          });
          this.axios
            .get(this.actions.order_delete + this.detail.ID)
            .then(({ data }) => {
              closeToast();
              let { code } = data;
              if (code == "SUCCESS") {
                this.goBack();
              }
            })
            .catch((error) => {
              //请求失败，触发catch中的函数 可省略
              console.log(
                "views.OrderDetail.deleteOrder.order_delete.error",
                error
              );
              closeToast();
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style src="../assets/css/order.css" scoped></style>
<style src="../assets/css/cartpc.css"></style>
<style scoped>
.price::before {
  content: var(--aog-currency);
  font-size: 70%;
}
.order-content.status {
  display: flex;
  flex-direction: column;
}
@media (max-width: 678px) {
	
	
	.custom-input {
	  background-color: #f0f0f0;
	  border: 1px solid #ccc;
	  border-radius: 4px;
	  padding: 8px;
	  font-size: 16px;
	}
	
   .goods-money-pay-content {
     width: 320px;
     background: #fff;
     border-radius:10px;
     position: fixed;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
     padding: 20px 25px 50px 14px;
     box-sizing: border-box;
   	text-align: center;
   }	
	
  .pc-order-pre {
    display: none;
  }
  .order-page {
    width: 100%;
    min-height: 100vh;
    padding: 16px 16px var(--van-submit-bar-height) 16px;
    box-sizing: border-box;
    background-color: #f7f7f9;
    --van-tabs-line-height: 60px;
    --van-tab-font-size: 16px;
  }

  .order-page .order-content.address {
    display: flex;
    align-items: center;
    background: #fff url(../assets/images/address_bg.png) repeat-x 0 100%;
    background-size: auto 4px;
  }

  .order-page .order-content.address .info {
    display: flex;
    flex-direction: column;
    width: 0;
    flex-grow: 1;
    line-height: 24px;
    font-size: 14px;
    color: #000;
    padding: 4px 0;
  }
  .order-page .order-content.address .info .area {
    color: #7c7c7c;
  }

  .order-page .order-content.address .info .phone {
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
  }

  .control-block {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: var(--van-submit-bar-height);
    padding: 0 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    justify-content: flex-end;
    align-items: center;

    --van-button-primary-border-color: #fe295f;
    --van-button-primary-background: #fe295f;
    --van-button-primary-color: #fff;
  }
  .control-block .van-button {
    margin-left: 10px;
  }

  .order-page .order-content {
    background-color: #fff;
    padding: 3vw;
    margin-bottom: 3vw;
    border-radius: 8px;
  }

  .order-content.status .status-title {
    font-size: 22px;
    font-weight: bold;
    color: #000;
  }

  .order-content.status .status-desc {
    font-size: 16px;
    color: #777;
    margin-top: 10px;
  }

  .order-content .big-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 36px;
    font-size: 16px;
    font-weight: bold;
  }
  .order-content .big-title .credit {
    font-size: 20px;
    color: #fe295f;
  }

  .order-content .order-info {
    border-bottom: 1px solid #eee;
  }
  .order-content .order-info:last-child {
    border-bottom: 0;
  }
  .order-content .order-info:first-child {
    padding-bottom: 2vw;
    margin-bottom: 2vw;
  }

  .order-content .order-info li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    font-size: 16px;
  }
  .order-content .order-info li .title {
    color: #000;
  }

  .order-content .order-info li .value {
    color: #777;
  }
  .order-content .order-info li .value .copy {
    font-size: 14px;
    color: #000;
  }

  .order-content .order-info li .value.price {
    color: #000;
    font-weight: bold;
    font-size: 18px;
  }

  .order-content .order-info li .value.total-money {
    font-size: 20px;
    color: #fe295f;
  }

  .order-page .countdown-placeholder {
    height: 36px;
  }

  .order-page .countdown {
    text-align: center;
    line-height: 52px;
    font-size: 14px;
    color: #333;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #f7f7f9;
  }

  .order-page .countdown > span {
    color: #fe295f;
    font-weight: bold;
  }
}

@media (min-width: 679px) {
  .order-page {
    display: none;
  }
  .pc-order-pre .address li:hover {
    background-color: transparent;
  }
  .pc-order-pre .cart-menu {
    --van-button-primary-border-color: #fe295f;
    --van-button-primary-background: #fe295f;
    --van-button-primary-color: #fff;
  }
  .pc-order-pre .order-content .status-title {
    font-size: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #000;
  }
  .pc-order-pre .order-content.order-info {
    display: flex;
  }
  .pc-order-pre .order-content.order-info .c {
    width: 50%;
  }
  .pc-order-pre .order-content.order-info .c .info:last-child {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #eee;
  }
  .pc-order-pre .order-content.order-info .c .info li {
    display: flex;
    height: 32px;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }
  .pc-order-pre .order-content.order-info .c .info li .title {
    color: #000;
  }

  .pc-order-pre .order-content.order-info .c .info li .value {
    color: #777;
  }
  .pc-order-pre .order-content.order-info .c .info li .value .copy {
    color: #000;
    cursor: pointer;
  }

  .pc-order-pre .order-content.order-info .c .info li .value.price {
    color: #000;
    font-weight: bold;
    font-size: 20px;
  }
  .pc-order-pre .order-content.order-info .c .info li .value.total-money {
    font-size: 22px;
    color: #fe295f;
  }
}
</style>
